<template>
  <AModalContent
    class="ape-mint-modal"
    :class="type"
    :show-close="canClose"
  >
    <template v-if="type === 'minting'">
      <div class="ape-mint-modal-title">
        Confirm transaction to mint ape
      </div>
      <div class="ape-mint-modal-description">
        Old ape will be burned and new one
        <br>will be sent to your wallet
      </div>
    </template>
    <template v-else>
      <div class="ape-mint-modal-title">
        You successfully changed your ape
      </div>
      <div class="ape-mint-modal-description">
        We will update your Apes metadata
        <br>within a few minutes.
      </div>
    </template>
    <CollectionCardImage
      :image="image"
      :traits="traits"
    />
    <div class="ape-mint-modal-actions">
      <a
        href="#"
        class="ape-mint-modal-action ape-mint-modal-twitter"
        @click.prevent="shareTwitter"
      >
        Share
        <ITwitter width="16" />
      </a>
      <div class="ape-mint-modal-action-row">
        <a
          href="#"
          class="ape-mint-modal-action ape-mint-modal-lootbox"
          @click.prevent="openLootBox"
        >Open Lootbox</a>
        <a
          href="#"
          class="ape-mint-modal-action ape-mint-modal-customize"
          @click.prevent="customizeAnotherApe"
        >Customize another ape</a>
      </div>
      <a
        href="#"
        class="ape-mint-modal-action ape-mint-modal-collection"
        @click.prevent="openMyCollection"
      >
        <IArrowLeft width="7" />Return to My Collection
      </a>
    </div>
  </AModalContent>
</template>
<script>
import { computed, defineComponent } from "vue"
import CollectionCardImage from "../Collection/CollectionCard/CollectionCardImage.vue"
import AModalContent from "../Modal/AModalContent.vue"
import ITwitter from "@/assets/icons/twitter.svg?inline"
import { useRoute, useRouter } from "vue-router"
import { useApeConstructorStore } from "@/store/ape-constructor"
import { shareApeTwitter } from "@/helpers/ape-constructor/ape-twitter-share"
import { useWalletCollectionStore } from "@/store/wallet-collection"
import IArrowLeft from "@/assets/icons/arrow-left.svg?inline"
export default defineComponent({
  components: { IArrowLeft, CollectionCardImage, ITwitter, AModalContent },
  props: {
    image: String,
    type: String
  },
  setup(props, { emit }) {
    const route = useRoute()
    console.log(route.name)
    const canClose = computed(() => route.name === 'ApeConfigurator' ? props.type !== 'done' : true)
    emit('init', { closeOnOverlay: canClose.value })
    const store = useApeConstructorStore()
    const traits = computed(() => {
      const items = {
        'Body Type': store.bodyTypeId === 1 ? 'Fur' : 'Skin',
        Gender: store.genderId == 1 ? "Male" : "Female",
        Guild: store.guild
      }
      store.equippedTraits.forEach(trait => items[trait.categoryName] = trait.name)
      return items
    })
    const router = useRouter()
    const shareTwitter = async () => {
      await shareApeTwitter()

    }
    const openLootBox = () => {
      router.push({ name: "LootBox" })
      emit('close')
    }
    const customizeAnotherApe = () => {
      const currentId = store.ape?.id
      const anotherApe = useWalletCollectionStore().apes.find(ape => ape.id !== currentId && !ape.pending)
      if (anotherApe) {
        router.push({ name: "ApeConfigurator", params: { id: anotherApe.id } })
      } else {
        router.push({ name: "Collection", })
      }
      emit('close')
    }
    const openMyCollection = () => {
      router.push({ name: "Collection" })
      emit('close')
    }
    return { traits, openMyCollection, canClose, openLootBox, shareTwitter, customizeAnotherApe }

  }
})
</script>
<style lang="scss">
.ape-mint-modal {
  background: linear-gradient(180deg, #17111f 0%, #22080a 100%);
  padding: 30px;
  padding-bottom: 15px;
  width: 90%;
  max-width: 400px;
  max-height: 100%;
  overflow: auto;
  font-family: var(--font-oxanium);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
  }
  .collection-card-image {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    img {
      min-height: 0;
      flex: 1;
      object-fit: contain;
    }
  }
  &-description {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
  &-actions {
    margin-top: 30px;
  }
  &-action {
    transition: 0.2s;
    padding: 8px 12px;

    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    border-radius: 5px;
    &:hover {
      filter: brightness(0.8);
    }
    &-row {
      display: flex;
      margin-top: 8px;
    }
  }
  &-twitter {
    background-color: #1da1f2;
    padding: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-self: center;
    svg {
      margin-left: 5px;
    }
  }
  &-lootbox {
    flex: 1;
    background-color: var(--primary);
    margin-right: 5px;
  }
  &-customize {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: var(--gray-light);
  }
  &-collection {
    padding: 0;
    margin-top: 14px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 1;
      filter: none;
    }
    svg {
      margin-right: 5px;
      transform: translateY(-1px);
    }
  }
  &.done & {
    &-title {
      color: #00e04d;
    }
  }
  @media screen and (max-width: 560px) {
    padding: 30px 15px;
    &-action {
      &-row {
        flex-direction: column;
      }
    }
    &-lootbox {
      margin-right: 0;
    }
    &-customize {
      margin-top: 8px;
    }
    &-collection {
      margin-top: 8px;
    }
  }
}
</style>